import { useReducer } from 'react';
import { useRouter } from 'next/router';

export const useNavItem = (href: string | object) => {
  const router = useRouter();
  const [isOpen, toggle] = useReducer((isOpen) => !isOpen, false);
  const isActive =
    router.pathname === href || router.pathname.includes(href as string);

  return {
    isOpen,
    toggle,
    isActive,
  };
};
