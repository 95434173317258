import { useCallback } from 'react';

import Image from 'next/image';

import { RichText } from 'prismic-reactjs';

import { CSSTransition } from 'react-transition-group';

import {
  IAddressLine,
  IContactLine,
  IFooterProps,
  IPageLink,
  Result,
} from './interfaces';
import { useFooter } from './footer.hooks';
import {
  CompanyAddress,
  CompanyAddressContact,
  CompanyAddressLine,
  ContactInformation,
  ContactLink,
  FooterClutchWidget,
  FooterInner,
  FooterMicrosoftPartner,
  FooterNavMenu,
  FooterPageLink,
  FooterWrapper,
} from './footer.styles';
import { Routes } from '../../../models/enums/routes';
import {
  FooterContactForm,
  FormTitle,
  ResultButtonLink,
  ResultContent,
  ResultDescription,
  ResultIcon,
  ResultTitle,
} from './footerForm.styles';
import { SocialMedia } from './SocialMedia/SocialMedia';
import { ClutchWidget } from './ClutchWidget/ClutchWidget';
import { imageSerializer } from '../../../utils/imageSerializer';

export const Footer = ({ data }: IFooterProps): JSX.Element | null => {
  const { footerRef, submitted, result } = useFooter({ data });

  const renderLink = useCallback((pageLink: IPageLink) => {
    const label = RichText.asText(pageLink.name);
    const page_link = pageLink.page_url;

    return (
      page_link && (
        <div key={label}>
          <FooterPageLink href={page_link}>{label}</FooterPageLink>
        </div>
      )
    );
  }, []);

  if (!data) {
    return null;
  }

  return (
    <FooterWrapper ref={footerRef}>
      <FooterInner>
        <ContactInformation>
          <FooterNavMenu>{data.page_link?.map(renderLink)}</FooterNavMenu>
          <div>
            <CompanyAddress>
              <CompanyAddressContact>
                {data.company_address_lines.map(
                  (address_line: IAddressLine) => {
                    const label = RichText.asText(address_line.label);

                    return (
                      <CompanyAddressLine key={label}>
                        {label}
                      </CompanyAddressLine>
                    );
                  }
                )}
              </CompanyAddressContact>
              <CompanyAddressContact>
                {data.company_contact_lines.map(
                  (contact_line: IContactLine) => {
                    const label = RichText.asText(contact_line.label);
                    const link = contact_line.link.url;

                    return (
                      <ContactLink key={label} href={link}>
                        {label}
                      </ContactLink>
                    );
                  }
                )}
              </CompanyAddressContact>
            </CompanyAddress>
          </div>
          <SocialMedia />
          <FooterClutchWidget>
            <ClutchWidget />
          </FooterClutchWidget>
          <FooterMicrosoftPartner>
            <Image {...imageSerializer(data.microsoft_partner_image)} />
          </FooterMicrosoftPartner>
        </ContactInformation>

        <FooterContactForm>
          {!submitted && (
            <>
              <FormTitle>{RichText.asText(data.contact_form_title)}</FormTitle>
              <div id="contactForm"></div>
            </>
          )}
          <CSSTransition
            in={submitted}
            timeout={300}
            unmountOnExit
            classNames={'contact-form-result'}
          >
            <div className={'footer__contact-form-result contact-form-result'}>
              <ResultContent>
                <ResultIcon>
                  {/*TODO: should be visibility hidden/opacity 0 to be preloaded*/}
                  {result === Result.Success && (
                    <Image
                      {...imageSerializer(data.contact_form_success_icon!)}
                    />
                  )}

                  {result === Result.Error && (
                    <Image
                      {...imageSerializer(data.contact_form_error_icon!)}
                    />
                  )}
                </ResultIcon>

                <ResultTitle>
                  {result === Result.Success &&
                    RichText.asText(data.contact_form_success_title)}
                  {result === Result.Error &&
                    RichText.asText(data.contact_form_error_title)}
                </ResultTitle>

                <ResultDescription>
                  {result === Result.Success &&
                    RichText.asText(data.contact_form_success_description)}
                  {result === Result.Error &&
                    RichText.asText(data.contact_form_error_description)}
                </ResultDescription>

                {result === Result.Success && (
                  <ResultButtonLink href={Routes.Home}>
                    {RichText.asText(data.contact_form_success_button)}
                  </ResultButtonLink>
                )}

                {result === Result.Error && (
                  <ResultButtonLink href={Routes.EstimateProject}>
                    {RichText.asText(data.contact_form_error_button)}
                  </ResultButtonLink>
                )}
              </ResultContent>
            </div>
          </CSSTransition>
        </FooterContactForm>
      </FooterInner>
    </FooterWrapper>
  );
};
