import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { mq } from '../../../../styles/mixins';
import { INavList } from './interfaces';
import { StyledNavItem } from './NavItem/navItem.styles';

export const StyledList = styled.ul<INavList>`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: auto;
  width: 100vw;
  overflow: scroll;
  transition: height 500ms ease-in-out;

  ${mq.xLarge(css`
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    visibility: visible;
    overflow: visible;
  `)};

  ${({ active }) =>
    active &&
    css`
      width: 100%;
      opacity: 1;
      overflow-y: scroll;

      ${mq.xLarge(css`
        width: auto;
        height: auto;
        margin: 0;
      `)};
    `};
`;

export const StyledNavItemButton = styled(StyledNavItem)<INavList>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: 0;
  margin-bottom: 0;

  ${mq.xLarge(css`
    margin-left: 2.5rem;
    flex: 1;
  `)};
`;
