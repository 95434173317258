export const pageView = (url: URL): void => {
  window.gtag('config', process.env.NEXT_PUBLIC_GA_ID as string, {
    page_path: url,
  });
};

export const downloadFile = (params: any): void => {
  window.gtag('event', 'file_download', {
    event_category: params?.id,
    event_label: params?.customerId,
    value: 0,
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

export const event = ({ action, category, label, value }: GTagEvent): void => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
