import { NavItem } from './NavItem/NavItem';
import { Routes } from '../../../../models/enums/routes';
import { RichText } from 'prismic-reactjs';
import { DropdownItem, DropdownMenu } from '../Dropdown/Dropdown';
import { NavLink } from '../../../link/NavLink';
import { StyledList, StyledNavItemButton } from './navItems.styles';
import { NavDescription, StyledNavLinkButton } from './NavItem/navItem.styles';

export const NavItems = ({
  menuActive,
  data,
  itemsRef,
  buttonRef,
  navDescr,
}: any) => {
  return (
    <StyledList ref={itemsRef}>
      {/* TODO: Active class name in the ul element should be sufficient */}
      <NavItem
        menuActive={menuActive}
        href={Routes.Clients}
        withGradient
        label={RichText.asText(data.link_clients)}
      />

      <NavItem
        menuActive={menuActive}
        href={Routes.Services}
        withGradient
        label={RichText.asText(data.link_services)}
      >
        <DropdownMenu>
          {data.services_sublinks.map(({ link, label }: any, index: number) => (
            <DropdownItem
              key={`${RichText.asText(label)}${index}`}
              href={`${Routes.ServicesId}${RichText.asText(link)}`}
              label={RichText.asText(label)}
            />
          ))}
        </DropdownMenu>
      </NavItem>

      <NavItem
        menuActive={menuActive}
        href={Routes.AboutUs}
        withGradient
        label={RichText.asText(data.link_about_us)}
      />

      <NavItem
        menuActive={menuActive}
        href={Routes.Insights}
        withGradient
        label={RichText.asText(data.link_insights)}
      />

      <NavItem
        menuActive={menuActive}
        href={Routes.Careers}
        withGradient
        label={RichText.asText(data.link_careers)}
      />

      <StyledNavItemButton active={menuActive} ref={buttonRef}>
          <StyledNavLinkButton  href={Routes.EstimateProject}>
            {RichText.asText(data.link_cta)}
          </StyledNavLinkButton>
      </StyledNavItemButton>

      <NavDescription active={menuActive} ref={navDescr}>
        {RichText.asText(data.nav_description)}
      </NavDescription>
    </StyledList>
  );
};
