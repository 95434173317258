import { useClutchWidget } from './clutchWidget.hooks';

export const ClutchWidget = () => {
  useClutchWidget();

  return (
    <div>
      <div
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="1"
        data-height="40"
        data-nofollow="true"
        data-expandifr="true"
        data-darkbg="darkbg"
        data-clutchcompany-id="568337"
      ></div>
    </div>
  );
};
