import styled from '@emotion/styled';
import { defaultContainerSize, mq } from '../../../styles/mixins';
import { css } from '@emotion/react';
import { THEME } from '../../../common/consts/theme';
import { rgba } from 'emotion-rgba';
import Script from 'next/script';
import { ScriptProps } from 'next/dist/client/script';
import Link from 'next/link';
import { ParagraphS } from '../Typography/Typography';

export const FooterClutchWidget = styled.div`
  order: 4;
  margin-bottom: 2rem;
`;

export const ScriptWrapper = (props: ScriptProps) => (
  <Script
    {...props}
    defer
    src={'https://js.hsforms.net/forms/v2.js'}
    strategy="worker"
  />
);

export const FooterMicrosoftPartner = styled.div`
  position: relative;
  order: 5;
  max-width: 11rem;
  max-height: 3rem;
`;

export const ContactLink = styled(Link)`
  display: block;
  font-style: normal;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.nunito};
  letter-spacing: 0.0125rem;
  color: ${({ theme }) => theme.colors.core_white};
  text-decoration: none;
  align-self: flex-start;
`;

export const FooterWrapper = styled.footer`
  background: ${({ theme }) => theme.colors.core_black};
  width: 100%;
  position: relative;
  z-index: 0;
`;

export const FooterInner = styled.div`
  ${defaultContainerSize};

  padding-top: 4rem;
  padding-bottom: calc(5rem + ${({ theme }) => theme.safeArea.safeAreaBottom});
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;

  ${mq.small(css`
    padding-top: 4rem;
    padding-bottom: calc(5rem + ${THEME.safeArea.safeAreaBottom});
  `)};

  ${mq.xLarge(css`
    padding-top: 5.5rem;
    padding-bottom: calc(7.5rem + ${THEME.safeArea.safeAreaBottom});
  `)};
`;

export const ContactInformation = styled.div`
  order: 2;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  ${mq.xLarge(css`
    justify-content: space-between;
    flex: 1;
  `)};
`;

export const CompanyAddress = styled.address`
  font-family: ${({ theme }) => theme.font.nunito};
  font-weight: 300;
  font-size: 1rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.grey};
  flex: 0 0 100%;
  margin-top: 2rem;

  ${mq.small(css`
    flex: 0 0 50%;
  `)};

  ${mq.xLarge(css`
    margin-top: 2.5rem;
  `)};
`;

export const CompanyAddressContact = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const CompanyAddressLine = styled.p`
  ${ParagraphS};
  margin: 0;
  font-style: normal;
  letter-spacing: 0.0125rem;
`;

export const FooterNavMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.xLarge(css`
    margin-top: 4rem;
  `)};
`;

export const FooterPageLink = styled(Link)`
  margin: 0;
  padding-bottom: 1rem;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.font.questrial};
  color: ${({ theme }) => theme.colors.core_white};
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0.75rem;
  }

  &:hover {
    transition: color 200ms ease-in-out;
    position: relative;
    color: ${rgba('#FFFFFF', 0)};
    background: linear-gradient(67.33deg, #0e4ed7 -19.99%, #f94743 98.54%);
    -webkit-background-clip: text;
    background-clip: text;
  }
`;
