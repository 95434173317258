import { css, SerializedStyles } from '@emotion/react';
import { Breakpoint } from './breakpoint';

export const defaultContainerSize = css`
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 77.125rem;

  @media screen and (min-width: 576px) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const buttonHover = css`
  &:before {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4rem;
    background: linear-gradient(67.33deg, #0e4ed7 -19.99%, #f94743 98.54%);
  }

  &:focus {
    outline: none;
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
  }
`;

export const boxShadow = css`
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(65, 70, 176, 0.86);
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4rem;
    z-index: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

export const pseudoElementDefaultContent = css`
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

type MediaQueries = Record<
  Breakpoint,
  (cssHelper: SerializedStyles) => SerializedStyles
>;

export const breakpoints: Record<Breakpoint, number> = {
  xSmall: 320,
  mSmall: 375,
  smallIp: 500,
  small: 576,
  medium: 768,
  mobileMax: 991,
  large: 992,
  xLarge: 1200,
  xxLarge: 1280,
  xxxLarge: 1400,
};

export const mq = Object.keys(breakpoints).reduce<MediaQueries>(
  (acc, label) => {
    const prefix = 'min-width:';
    const suffix = 'px';

    acc[label as Breakpoint] = (cls: any) =>
      css`
        @media (${prefix + breakpoints[label as Breakpoint] + suffix}) {
          ${cls};
        }
      `;

    return acc;
  },
  {} as MediaQueries
);

export const mqMax = Object.keys(breakpoints).reduce<MediaQueries>(
  (acc, label) => {
    const prefix = 'max-width:';
    const suffix = 'px';

    acc[label as Breakpoint] = (cls: any) =>
      css`
        @media (${prefix + breakpoints[label as Breakpoint] + suffix}) {
          ${cls};
        }
      `;

    return acc;
  },
  {} as MediaQueries
);
