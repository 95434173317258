import { useEffect } from 'react';

export const useClutchWidget = () => {
  useEffect(() => {
    // @ts-ignore
    if (window?.CLUTCHCO && !window.CLUTCHCO.loaded) {
      // @ts-ignore
      window.CLUTCHCO.Init();
    }
  }, []);

  return {};
};
