import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ParagraphS } from '../../Typography/Typography';

export const DropdownMenuWrapper = styled.div`
  position: absolute;
  top: 58px;
  width: 255px;
  transform: translateX(25%);
  background-color: transparent;
  overflow: hidden;
  transition: height var(500ms) ease;
  box-shadow: 8px 8px 24px rgba(23, 0, 89, 0.29);
  padding-top: 8px;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(
      100.54deg,
      #0e4ed7 0%,
      #7b4b93 48.44%,
      #f94743 100%
    );
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 1.5rem;
  gap: 8px;
`;

export const DropdownItemWrapper = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.core_black};
  `
);

export const DropdownLink = styled.a(
  ({ theme }) => css`
    ${ParagraphS};
    line-height: 1.5;
    color: ${theme.colors.core_black};
    cursor: pointer;
    text-decoration: none;
    width: auto;
    display: inline-block;
    height: auto;

    &:hover {
      background: linear-gradient(
        100.54deg,
        #0e4ed7 0%,
        #7b4b93 48.44%,
        #f94743 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  `
);

export const Pointer = styled.div`
  position: absolute;
  top: -4px;
  left: 24px;
  z-index: -1;
  transform: translateY(8px) rotate(45deg);
  width: 16px;
  height: 16px;
  background: #0e4ed7;
`;
