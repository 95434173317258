import styled from '@emotion/styled';
import { mq } from '../../../styles/mixins';
import { css } from '@emotion/react';
import Link from 'next/link';

export const StyledHeader = styled.header`
  background: ${({ theme }) => theme.colors.core_black};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;

  & > a {
    & > svg {
      display: block;
    }
  }
`;

export const HeaderNav = styled.nav`
  margin: 0 auto;
  padding: 0.75rem 1rem;
  max-width: 77.125rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${mq.small(css`
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  `)};

  ${mq.xLarge(css`
    padding: 0 2rem;
  `)};
`;

export const NavLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-bottom: 0;
`;

export const LogoAnimated = styled.svg`
  transition: opacity 300ms ease-in-out;
  opacity: 0;
`;

export const LogoLink = styled(Link)`
  display: flex;
  cursor: pointer;

  &:hover {
    ~ ${LogoAnimated} {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;

    ~ ${LogoAnimated} {
      opacity: 1;
    }
  }
`;
