import * as React from 'react';
import { StyledNavItem, StyledNavLink } from './navItem.styles';
import { useNavItem } from './navItem.hooks';

type NavItemProps = {
  menuActive: boolean;
  children?: any;
  href: string | object;
  label: string;
  withGradient?: boolean;
};

export const NavItem = ({
  menuActive,
  children,
  href,
  label,
  withGradient,
}: NavItemProps) => {

  const { isOpen, toggle, isActive } = useNavItem(href);

  if(!href) {
    return null;
  }

  return (
    <StyledNavItem
      onMouseEnter={toggle}
      onMouseLeave={toggle}
      active={menuActive}
    >
      <StyledNavLink href={href} passHref withgradient={withGradient? 1:0} isactive={isActive? 1:0}>
          {label}
      </StyledNavLink>

      {isOpen && children && children}
    </StyledNavItem>
  );
};
