import { useEffect, useRef, useState } from 'react';
import { IWindowSize, useWindowSize } from '../../../hooks/useWindowSize';
import { Router } from 'next/router';
import classNames from 'classnames';

export const useHeader = () => {
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const windowSize: IWindowSize = useWindowSize();
  const itemsRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLLIElement>(null);
  const navDescr = useRef<HTMLLIElement>(null);
  const { medium } = useWindowSize();

  useEffect(() => {
    if (menuActive && windowSize.width && windowSize.width > 1200) {
      setMenuActive(false);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (
      windowSize?.width &&
      windowSize?.height &&
      itemsRef?.current &&
      buttonRef?.current &&
      navDescr?.current
    ) {
      if (windowSize.width >= 1200) {
        itemsRef.current.style.height = 'auto';
        buttonRef.current.style.marginTop = '0';
        buttonRef.current.style.marginBottom = `0`;
      } else {
        if (windowSize.height - 568 <= 0) {
          buttonRef.current.style.marginBottom = `0`;
        } else {
          navDescr.current.style.marginTop = `auto`;
          buttonRef.current.style.marginBottom = `2.5rem`;
        }

        if (menuActive) {
          itemsRef.current.style.height = `calc(${windowSize.height}px - 3rem + env(safe-area-inset-bottom, 0))`;
        } else {
          itemsRef.current.style.height = '0';
        }
      }
    }
  }, [windowSize.height, windowSize.width, menuActive]);

  const onRouteChangeStart = () => {
    setMenuActive(false);
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart);

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
    };
  });

  return {
    menuActive,
    setMenuActive,
    windowSize,
    itemsRef,
    buttonRef,
    navDescr,
    medium,
  };
};
