import Link from 'next/link';
import { Fragment } from 'react';

import { IHeaderProps } from './interfaces';
import { Routes } from '../../../models/enums/routes';
import { useHeader } from './header.hooks';
import { HeaderNav, NavLogo, StyledHeader } from './header.styles';
import { Hamburger } from './Hamburger/Hamburger';
import { NavItems } from './NavItems/NavItems';

const Header = ({ data }: IHeaderProps): JSX.Element | null => {
  const { menuActive, setMenuActive, itemsRef, buttonRef, navDescr, medium } =
    useHeader();

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <style global jsx>{`
        html {
          overflow: ${menuActive ? 'hidden' : 'visible'};
        }
      `}</style>

      <StyledHeader>
        <HeaderNav>
          <NavLogo>
            <Link
              href={Routes.Home}
              role="link"
              aria-label="Qodeca home page link"
            >
              {!medium ? (
                <svg
                  role="img"
                  aria-label="Qodeca Logo"
                  width="42"
                  height="36"
                  viewBox="0 0 42 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.26464 5.06167C7.56472 2.73744 11.8071 0 18.6051 0C23.9209 0 28.3677 1.65279 31.7923 5.01002C35.0124 8.16065 37.108 12.7058 37.108 18.0258C37.108 22.5794 35.503 27.2344 31.8955 30.836L26.5467 25.2174C27.7591 23.837 29.0322 21.5344 29.0322 18.0258C29.0322 14.4619 27.601 11.8278 26.0676 10.2783C24.3809 8.6255 21.6719 7.33426 18.554 7.33426C15.5895 7.33426 12.9316 8.4189 11.0915 10.2783C9.60924 11.7245 8.07585 14.2553 8.07585 18.0774C8.07585 21.228 9.09811 23.7589 11.1937 25.8249C13.3916 27.9425 15.845 28.6656 18.5029 28.6656C19.5874 28.6656 20.5724 28.5399 21.464 28.3167L27.2614 34.1418C24.8269 35.325 21.9621 35.9999 18.6051 35.9999C12.216 35.9999 7.8714 33.4174 5.31575 30.9382C2.04452 27.8392 0 23.1907 0 18.129C0 13.1707 2.19786 8.16065 5.26464 5.06167Z"
                      fill="white"
                    />
                    <path
                      d="M41.8 36H34.1225L19.4072 21H27.7245L41.8 36Z"
                      fill="#F94743"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  role="img"
                  aria-label="Qodeca Logo"
                  width="175"
                  height="30"
                  viewBox="0 0 175 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M66.1139 14.5822C66.1139 10.8635 64.6933 6.93593 61.7267 4.13649C58.8855 1.42061 54.8743 0 50.7379 0C46.6014 0 42.5902 1.42061 39.749 4.13649C36.7824 6.93593 35.3618 10.8635 35.3618 14.5822C35.3618 18.259 36.7824 22.1448 39.749 24.9861C42.5902 27.7019 46.6014 29.1225 50.7379 29.1225C54.8743 29.1225 58.8855 27.7019 61.7267 24.9861C64.6933 22.1448 66.1139 18.259 66.1139 14.5822ZM50.7379 7.0195C52.6599 7.0195 54.7908 7.77158 56.3367 9.23398C57.632 10.4875 58.593 12.4095 58.593 14.5822C58.593 16.7131 57.632 18.6351 56.3367 19.9304C55.0415 21.2256 53.203 22.1031 50.7379 22.1031C48.3145 22.1031 46.4342 21.1838 45.0972 19.8886C44.0526 18.8858 42.8827 17.1727 42.8827 14.7075C42.8827 12.7437 43.593 10.6964 45.0972 9.23398C46.6014 7.7298 48.7323 7.0195 50.7379 7.0195Z"
                    fill="white"
                  />
                  <path
                    d="M80.2134 28.4958C84.9766 28.4958 88.6953 26.4902 90.9097 24.2758C93.6256 21.5599 94.5448 18.3008 94.5448 14.6239C94.5448 11.6156 93.9181 7.60445 90.6591 4.47075C87.0657 1.00278 82.3861 0.62674 79.5866 0.62674H70.102V28.4958H80.2134ZM77.3304 6.7688H79.0853C80.8819 6.7688 83.1382 6.93593 84.8513 8.52367C86.2301 9.81894 87.024 12.0334 87.024 14.6239C87.024 17.7159 85.8123 19.4707 84.893 20.3482C83.0128 22.1866 80.5476 22.3538 78.7928 22.3538H77.3304V6.7688Z"
                    fill="white"
                  />
                  <path
                    d="M114.99 0.62674H99.154V28.4958H114.99V22.3538H106.382V17.5487H114.53V11.4067H106.382V6.7688H114.99V0.62674Z"
                    fill="white"
                  />
                  <path
                    d="M141.485 19.1783C138.937 21.9777 136.263 22.1031 135.176 22.1031C133.129 22.1031 131.207 21.3092 129.995 20.181C128.533 18.8022 127.739 16.6713 127.739 14.7493C127.739 12.7855 128.449 10.6128 130.162 9.02507C131.499 7.81337 133.087 7.18662 135.176 7.18662C136.388 7.18662 139.146 7.35376 141.485 10.2368V1.58774C138.477 0.376044 136.304 0.167131 134.967 0.167131C130.622 0.167131 126.903 2.00557 124.438 4.51253C121.931 7.06128 120.218 10.8217 120.218 14.7911C120.218 18.4262 121.722 22.2284 124.438 24.9443C126.736 27.2005 130.371 29.1225 135.218 29.1225C137.015 29.1225 138.602 28.7883 141.485 27.7437V19.1783Z"
                    fill="white"
                  />
                  <path
                    d="M166.67 28.4958H174.399L163.912 0.62674H156.015L145.319 28.4958H152.965L154.845 23.8161H164.957L166.67 28.4958ZM156.851 18.3008L159.901 9.27576L163.035 18.3008H156.851Z"
                    fill="white"
                  />
                  <g clipPath="url(#clip0_9245_81756)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.40819 4.21806C6.33409 2.2812 9.88632 0 15.5785 0C20.0294 0 23.7529 1.37733 26.6203 4.17502C29.3166 6.80055 31.0713 10.5882 31.0713 15.0215C31.0713 18.8161 29.7274 22.6953 26.7068 25.6966L22.2282 21.0144C23.2433 19.8641 24.3092 17.9453 24.3092 15.0215C24.3092 12.0516 23.1109 9.85649 21.827 8.56525C20.4146 7.18792 18.1463 6.11188 15.5357 6.11188C13.0534 6.11188 10.8279 7.01575 9.28715 8.56525C8.04601 9.77041 6.76207 11.8794 6.76207 15.0645C6.76207 17.69 7.61803 19.7991 9.37275 21.5207C11.2131 23.2854 13.2674 23.888 15.4929 23.888C16.401 23.888 17.2258 23.7832 17.9724 23.5972L22.8266 28.4514C20.7881 29.4374 18.3893 29.9999 15.5785 29.9999C10.2287 29.9999 6.59088 27.8478 4.45099 25.7818C1.71192 23.1993 0 19.3256 0 15.1075C0 10.9756 1.84031 6.80055 4.40819 4.21806Z"
                      fill="white"
                    />
                    <path
                      d="M34.9999 30H28.5714L16.25 17.5H23.2143L34.9999 30Z"
                      fill="#F94743"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_9245_81756">
                      <rect width="35" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </Link>
          </NavLogo>
          <Hamburger
            onClick={() => setMenuActive(!menuActive)}
            isActive={menuActive}
          />

          <NavItems
            menuActive={menuActive}
            data={data}
            itemsRef={itemsRef}
            buttonRef={buttonRef}
            navDescr={navDescr}
          />
        </HeaderNav>
      </StyledHeader>
    </Fragment>
  );
};

export { Header };
