import { RichTextBlock } from 'prismic-reactjs';
import { ImageFieldImage } from '@prismicio/types';
import { Style } from "util";

export const imageSerializer = (
  image: ImageFieldImage | RichTextBlock,
  props?: { [x: string]: string | number | null | object}
) => {
  const URL = image.url!;
  const imageUrl = URL.substr?.(0, URL.lastIndexOf('?')) || URL;
  const isFill = props?.layout === 'fill';
  const style = isFill ? {} : props?.style || {} as any

  if(props?.inset !== null){
    style.inset = props?.inset ? `${props.inset}` : 'inherit';
  }


  if (isFill) {
    return {
      src: imageUrl! + '?auto=compress,format',
      alt: image.alt! || '',
      quality: 100,
      priority: true,
      ...props,
      style
    };
  }

  style.maxWidth = '100%';
  style.height = props?.height ? props?.height : 'auto';


  return {
    src: imageUrl! + '?auto=compress,format',
    alt: image.alt! || '',
    quality: 100,
    width: image.dimensions?.width!,
    height: image.dimensions?.height!,
    priority: true,
    ...props,
    style
  };
};
