import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  DropdownItemWrapper,
  DropdownLink,
  DropdownMenuWrapper,
  InnerWrapper,
  Pointer,
} from './dropdown.styles';
import { NavLink } from '../../../link/NavLink';
import { useWindowSize } from '../../../../hooks/useWindowSize';

interface DropdownMenuProps {
  children: ReactNode;
}

export const DropdownMenu = (props: DropdownMenuProps) => {
  const { children, ...rest } = props;
  const [menuHeight, setMenuHeight] = useState<number | undefined>(undefined);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { xLarge } = useWindowSize();

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.offsetHeight!);
  }, []);

  if (!xLarge) {
    return null;
  }

  return (
    <DropdownMenuWrapper
      {...rest}
      ref={dropdownRef}
      style={{ height: menuHeight }}
    >
      <Pointer />
      <InnerWrapper>{children}</InnerWrapper>
    </DropdownMenuWrapper>
  );
};

export const DropdownItem = ({ children, href, label, ...rest }: any) => {

  return (
    <DropdownItemWrapper>
      <NavLink href={href} legacyBehavior>
        <DropdownLink>{label}</DropdownLink>
      </NavLink>
    </DropdownItemWrapper>
  );
};
