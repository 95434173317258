import styled from '@emotion/styled';
import { mq } from '../../../../styles/mixins';
import { css } from '@emotion/react';
import Link from "next/link";

export const FooterSocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem 0;
  order: 3;
`;

export const SocialMediaDefs = styled.svg`
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const SocialMediaIcon = styled.svg`
  display: inline-block;
`;

export const SocialMediaPath = styled.path`
  fill: ${({ theme }) => theme.colors.core_white};
`;

export const SocialMediaPathGradient = styled.path`
  transition: opacity 200ms ease-in-out;
  fill: url(#gradient);
  opacity: 0;
`;

export const SocialMediaLink = styled(Link)`
  display: inline-flex;

  &:hover {
    path {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;

    path {
      opacity: 1;
    }
  }
`;

export const SocialMediaWebsite = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;
