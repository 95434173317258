import { nunito, questrial } from './fonts';

type ThemeValue = {
  [key: string]: string;
};

export type CustomTheme = {
  colors: ThemeValue;
  font: ThemeValue;
  safeArea: ThemeValue;
};

export const THEME: CustomTheme = {
  colors: {
    primary: '#0E4ED7',
    accent: '#F94743',
    core_black: '#000000',
    core_white: '#FFFFFF',
    black: '#212121',
    darker_grey: '#667070',
    dark_grey: '#6E767D',
    grey: '#D8DFE4',
    light_grey: '#F4F4F4',
    case_study_table_first: '#254c87',
    case_study_table_second: '#008e9e',
  },
  font: {
    questrial: questrial.style.fontFamily,
    nunito: nunito.style.fontFamily,
  },
  safeArea: {
    safeAreaTop: 'env(safe-area-inset-top, 0px)',
    safeAreaBottom: 'env(safe-area-inset-bottom, 0px)',
    safeAreaRight: 'env(safe-area-inset-right, 0px)',
    safeAreaLeft: 'env(safe-area-inset-left, 0px)',
  },
};
