import { FC, Fragment, useEffect } from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import smoothscroll from 'smoothscroll-polyfill';
import { useRouter } from 'next/router';

import Header from 'components/core/Header';
import Footer from 'components/core/Footer';

import '../styles/main.scss';

import * as gtag from '../utils/gtag';
import { ThemeProvider } from '@emotion/react';
import { THEME } from '../common/consts/theme';
import { DefaultSeo } from 'next-seo';
import Script from 'next/script';
import GlobalStyle from '../styles/globalStyles';

const isProduction = process.env.NODE_ENV === 'production';

const NextApp: FC<AppProps<any>> = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    smoothscroll.polyfill();

    console.log('%c💼 Looking for a job?', 'color:#F94743; font-size:1rem');
    console.log(
      '%c🏃 Navigate to careers page!',
      'color:#F94743; font-size:1rem'
    );

    if (isProduction) {
      hotjar.initialize(
        Number(process.env.NEXT_PUBLIC_HOTJAR_ID) || 0,
        Number(process.env.NEXT_PUBLIC_HOTJAR_SV) || 0
      );
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GA_ID || '' });
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (isProduction) gtag.pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const SEO = pageProps.data?.seo;
  const pathUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`;

  return (
    <Fragment>
      <Script
        defer
        src={'https://js.hsforms.net/forms/v2.js'}
        strategy="afterInteractive"
      />
      <Script
        defer
        src={'https://widget.clutch.co/static/js/widget.js'}
        strategy="afterInteractive"
      />
      <Script
        defer
        id={'hs-script-loader'}
        src={'https://js.hs-scripts.com/9219940.js'}
        strategy={'afterInteractive'}
      />
      {isProduction && (
        <>
          <Script
            defer
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
          />
        </>
      )}
      <Script defer id="gtm-script" strategy="afterInteractive">
        {`
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-MPMTR66');`}
      </Script>

      <DefaultSeo
        title={SEO.seo_title}
        description={SEO.seo_description}
        canonical={pathUrl}
        openGraph={{
          title: SEO.seo_title,
          description: SEO.seo_description,
          url: pathUrl,
          images: [
            {
              url: `${SEO.seo_image?.url || ''}`,
              alt: `${SEO.seo_image?.alt || ''}`,
              width: SEO.seo_image?.dimensions?.width,
              height: SEO.seo_image?.dimensions?.height,
            },
          ],
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content: SEO.seo_keywords,
          },
        ]}
        noindex={false}
      />

      <Head>
        <meta charSet={'utf-8'} />
        <meta
          name={'viewport'}
          content={
            'width=device-width, height=device-height, initial-scale=1, minimum-scale=1.0, maximum-scale=5.0, viewport-fit=cover'
          }
        />
        <meta name={'theme-color'} content={'#000000'} />
        <meta name={'format-detection'} content={'telephone=no'} />
        <meta
          name={'p:domain_verify'}
          content={'f9e799582bde76524fe869f99b7d53e7'}
        />
        <title>{SEO.seo_title}</title>
      </Head>

      <ThemeProvider theme={THEME}>
        <GlobalStyle />
        <Fragment>
          <Header data={pageProps.data?.header} />
          <main>
            <Component {...pageProps} />
          </main>

          <Footer data={pageProps.data?.footer} />
        </Fragment>
      </ThemeProvider>
    </Fragment>
  );
};

export default NextApp;
