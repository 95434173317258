import { css } from '@emotion/react';
import { mq } from 'styles/mixins';
import { THEME } from '../../../common/consts/theme';

export const Header1 = css`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 400;
  font-family: ${THEME.font.questrial};
  letter-spacing: 0.0125rem;
  text-align: left;

  ${mq.small(css`
    font-size: 3rem;
    line-height: 3.5rem;
  `)};

  ${mq.xLarge(css`
    font-size: 4rem;
    line-height: 4rem;
  `)};
`;

export const Header2 = css`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;
  font-family: ${THEME.font.questrial};

  ${mq.xLarge(css`
    font-size: 3rem;
    line-height: 3.5rem;
    letter-spacing: -0.0125rem;
  `)};
`;

export const Header3 = css`
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: ${THEME.font.questrial};
  letter-spacing: -0.0125rem;

  ${mq.large(css`
    font-size: 2.5rem;
    line-height: 3.5rem;
  `)};
`;

export const Headline1 = css`
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: 400;
  font-family: ${THEME.font.questrial};
  letter-spacing: -0.0125rem;
`;

export const Headline4 = css`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: ${THEME.font.questrial};
`;

export const Subheader = css`
  font-family: ${THEME.font.questrial};
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.0125rem;
  text-align: left;
`;

export const ParagraphXs = css`
  font-family: ${THEME.font.nunito};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.875rem;
`;

export const ParagraphS = css`
  font-family: ${THEME.font.nunito};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`;

export const ParagraphM = css`
  font-family: ${THEME.font.nunito};
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2rem;
`;

export const ParagraphL = css`
  font-family: ${THEME.font.nunito};
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.25rem;
`;

export const ParagraphXL = css`
  font-family: ${THEME.font.nunito};
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
`;

export const ParagraphXXL = css`
  font-family: ${THEME.font.nunito};
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
`;
