import { IFooterProps, Result } from './interfaces';
import { IWindowSize, useWindowSize } from '../../../hooks/useWindowSize';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Router } from 'next/router';

export const useFooter = ({ data }: IFooterProps) => {
  const currentYear = new Date().getFullYear();
  const windowSize: IWindowSize = useWindowSize();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<Result>(Result.None);

  const footerRef = useRef<HTMLDivElement>(null);

  const onRouteChangeComplete = () => {
    setSubmitting(false);
    setSubmitted(false);
    setResult(Result.None);
  };

  useEffect(() => {
    Router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: '9219940',
          formId: '45f7b4ec-757f-43a0-bbc0-ba7ecaf5ed53',
          target: '#contactForm',
          onFormReady: function () {
            handleFormValidation();
          },
          onFormSubmitted() {
            handleSubmit();
          },
        });
      }
    });
  }, []);

  const handleFormValidation = useCallback(() => {
    const formId = 'hsForm_45f7b4ec-757f-43a0-bbc0-ba7ecaf5ed53';
    const form = document?.getElementById(formId);

    if (!form) {
      return;
    }

    const inputFields = form?.querySelectorAll<HTMLInputElement>('input');
    const textareas = form?.querySelectorAll<HTMLTextAreaElement>('textarea');

    const initialValues: Record<string, string | boolean> = {};

    const updateSubmitButtonState = () => {
      const submitButton = form?.querySelector<HTMLInputElement>(
        'input[type="submit"].hs-button.primary.large'
      );

      if (!submitButton) return;

      const isAllNotEmpty = Object?.values(initialValues)?.every(
        (value) => value !== ''
      );
      const areAllCheckboxesFalse = Object?.values(initialValues)?.every(
        (value) => value !== false
      );

      if (!isAllNotEmpty || !areAllCheckboxesFalse) {
        submitButton?.classList?.remove('hs-button-custom-class-filled');
      } else {
        setTimeout(() => {
          const errorMessages =
            form?.getElementsByClassName('hs-error-msgs')?.length;

          if (!errorMessages) {
            submitButton?.classList?.add('hs-button-custom-class-filled');
          } else {
            submitButton?.classList?.remove('hs-button-custom-class-filled');
          }
        }, 2000);
      }
    };

    const handleInputEvent = (input: any) => {
      const currentValue =
        input?.type === 'checkbox' ? input?.checked : input?.value;
      initialValues[input?.name] = currentValue;
      updateSubmitButtonState();
    };

    inputFields?.forEach((input) => {
      if (
        input?.name === 'hs_context' ||
        input?.type === 'submit' ||
        input?.name === 'nda'
      )
        return;

      initialValues[input.name] =
        input?.type === 'checkbox' ? false : input?.value;
      input.setAttribute(
        'data-form-value',
        initialValues[input?.name]?.toString()
      );

      input?.addEventListener('input', () => handleInputEvent(input));
    });

    textareas?.forEach((textarea) => {
      initialValues[textarea?.name] = '';
      textarea?.addEventListener('input', () => {
        initialValues[textarea?.name] = textarea?.value;
        updateSubmitButtonState();
      });
    });

    form?.addEventListener('submit', (_) => {
      updateSubmitButtonState();
    });
  }, []);

  const handleTryAgain = () => {
    setSubmitting(false);
    setSubmitted(false);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setResult(Result.None);

    try {
      setSubmitting(false);
      setSubmitted(true);
      setResult(Result.Success);
    } catch (e: unknown) {
      setSubmitting(false);
      setSubmitted(true);
      setResult(Result.Error);
    }

    if (footerRef?.current && windowSize?.width && windowSize?.width < 1200) {
      const offsetPosition = footerRef.current.offsetTop - 60;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return {
    handleSubmit,
    handleTryAgain,
    footerRef,
    currentYear,
    windowSize,
    submitted,
    submitting,
    result,
  };
};
