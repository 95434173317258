import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { boxShadow, mq } from '../../../../../styles/mixins';
import { INavLink, INavList } from '../interfaces';
import { THEME } from '../../../../../common/consts/theme';
import { rgba } from 'emotion-rgba';
import Link from 'next/link';

const showItemsWithDelay = () => {
  let styles = '';

  for (let i = 1; i <= 5; i++) {
    styles += `
      &:nth-child(${i}) {
        transition-delay: ${i * 100}ms;
      }
    `;
  }

  return css`
    ${styles}
  `;
};

export const StyledNavItem = styled.li<INavList>`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 0;
  margin-bottom: 2.5rem;
  opacity: 0;
  transform: translateY(-1rem);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.25, 1);
  transition-duration: 400ms;

  &:first-of-type {
    margin-top: 3.5rem;
    margin-left: 0;
  }

  ${mq.xLarge(css`
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2.5rem;
    padding: 0.75rem 0;
    opacity: 1;
    transform: translateY(0);
    transition: none;

    &:first-of-type {
      margin-top: 0;
    }
  `)};

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      transform: translateY(0);

      ${showItemsWithDelay()};

      &:nth-child(6) {
        transition-delay: 600ms;
      }
    `};
`;

export const StyledNavLink = styled(Link)<INavLink>`
  cursor: pointer;
  color: ${THEME.colors.core_white};
  font-family: ${({ theme }) => theme.font.questrial};
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 2rem;
  width: auto;
  display: inline-block;
  height: auto;
  padding: 0.75rem 0;

  ${({ withgradient }) =>
    withgradient &&
    css`
      background: linear-gradient(
        67.33deg,
        ${THEME.colors.primary} -19.99%,
        ${THEME.colors.accent} 98.54%
      );
      background-clip: text;
      color: ${rgba(`${THEME.colors.core_white}`, 1)};
      transition: color 200ms ease-in-out;
      position: relative;

      &:hover {
        color: ${rgba(`${THEME.colors.core_white}`, 0)};
      }

      &:focus {
        color: ${rgba(`${THEME.colors.core_white}`, 0)};
        outline: none;
      }
    `};

  ${({ isactive }) =>
    isactive &&
    css`
      background: linear-gradient(
        67.33deg,
        ${THEME.colors.primary} -19.99%,
        ${THEME.colors.accent} 98.54%
      );
      background-clip: text;
      color: ${rgba(`${THEME.colors.core_white}`, 0)};
    `};
`;

export const StyledNavLinkButton = styled(StyledNavLink)`
  position: relative;
  padding: 0.75rem 3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4rem;
  text-align: center;
  transition: background-color 300ms ease-in-out;
  align-self: flex-end;

  ${boxShadow};

  &:before {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4rem;
    background: linear-gradient(67.33deg, #0e4ed7 -19.99%, #f94743 98.54%);
  }

  &:hover {
    background-color: transparent;
  }

  ${mq.xLarge(css`
    padding: 0.75rem 1.5rem;
  `)};

  &:focus {
    outline: none;
    background-color: transparent;
  }
`;

export const NavDescription = styled.li<INavList>`
  margin: 4rem 0 calc(1.5rem + ${THEME.safeArea.safeAreaBottom}) 0;
  font-size: 1rem;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.font.questrial};
  color: ${({ theme }) => theme.colors.core_white};
  text-align: center;
  opacity: 0;
  transition-delay: 600ms;
  transition-duration: 400ms;
  transform: translateY(-1rem);
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.25, 1);

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  ${mq.xLarge(css`
    display: none;
  `)};
`;
