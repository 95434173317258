export enum Routes {
  Home = '/',
  Insights = '/insights',
  InsightsArticleId = '/insights/article/',
  InsightsTag = '/insights/tag/',
  CaseStudiesId = '/case-studies/',
  Clients = '/clients',
  Careers = '/careers',
  CareersId = '/careers/',
  DocumentsPrivacyPolicy = '/documents/privacy-policy',
  Services = '/services',
  ServicesId = '/services/',
  AboutUs = '/about-us',
  EstimateProject = '/estimate-project',
}

export enum RouteNames {
  Home = '/',
  Insights = '/insights',
  InsightsArticleId = '/insights/article/',
  InsightsTag = '/insights/tag/',
  CaseStudiesId = '/case-studies/',
  Clients = '/clients',
  Careers = '/careers',
  CareersId = '/careers/',
  DocumentsPrivacyPolicy = '/documents/privacy-policy',
  Services = '/services',
  ServicesId = '/services/',
  AboutUs = 'AboutUs',
  EstimateProject = '/estimate-project',
}
