import {
  HamburgerBox,
  HamburgerInner,
  HamburgerWrapper,
} from './hamburger.styles';

export const Hamburger = ({ onClick, isActive }: any) => {
  return (
    <HamburgerWrapper
      tabIndex={0}
      aria-label="menu button"
      onClick={onClick}
      type="button"
      isActive={isActive}
    >
      <HamburgerBox className="hamburger__box">
        <HamburgerInner className="hamburger__inner" />
      </HamburgerBox>
    </HamburgerWrapper>
  );
};
