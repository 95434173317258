import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IHamburger } from './interfaces';
import { THEME } from '../../../../common/consts/theme';
import { mq } from '../../../../styles/mixins';

const HamburgerInnerContent = css`
  width: 2rem;
  height: 0.1875rem;
  background-color: ${THEME.colors.core_white};
  border-radius: 0.25rem;
  position: absolute;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`;

export const HamburgerBox = styled.span`
  width: 2rem;
  height: 1.1875rem;
  display: inline-block;
  position: relative;
  align-self: center;
`;

export const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -0.125rem;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${HamburgerInnerContent};

  &:before {
    content: '';
    display: block;
    top: 0.5rem;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;

    ${HamburgerInnerContent};
  }

  &:after {
    content: '';
    display: block;
    bottom: 0.5rem;
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    ${HamburgerInnerContent};
  }
`;

export const HamburgerWrapper = styled.button<IHamburger>`
  cursor: pointer;
  display: flex;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  height: 2.1875rem;
  padding: 0.5rem 0;
  overflow: visible;

  &:focus {
    outline: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${HamburgerInner} {
        background-color: ${THEME.colors.core_white};
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &:before {
          background-color: ${THEME.colors.core_white};
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }

        &:after {
          background-color: ${THEME.colors.core_white};
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
            transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    `};

  ${mq.xLarge(css`
    display: none;
  `)};
`;
