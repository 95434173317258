import { useEffect, useMemo, useState } from 'react';
import { breakpoints } from '../styles/mixins';
import { Breakpoint } from '../styles/breakpoint';

export interface IWindowSize {
  height: number | undefined;
  width: number | undefined;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  });

  const breakPoints = useMemo(() => {
    const result = {} as Record<Breakpoint, boolean>;

    Object.keys(breakpoints).forEach((key) => {
      result[key as Breakpoint] =
        windowSize.width! >= breakpoints[key as Breakpoint];
    });

    return result;
  }, [windowSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowSize, ...breakPoints };
};
