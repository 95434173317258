import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  boxShadow,
  buttonHover,
  defaultContainerSize,
  mq,
} from '../../../styles/mixins';
import {
  ParagraphS,
  ParagraphXL,
  ParagraphXXL,
} from '../Typography/Typography';

export const FooterContactForm = styled.div`
  order: 1;
  margin-bottom: 3.5rem;
  flex: 0 0 100%;
  position: relative;

  ${mq.small(css`
    flex: 0 0 100%;
  `)};

  ${mq.xLarge(css`
    order: 2;
    flex: 2;
    max-width: 42rem;
    margin-bottom: 0;
  `)};
`;

export const FormTitle = styled.p`
  ${ParagraphXL};
  color: ${({ theme }) => theme.colors.core_white};
  font-family: ${({ theme }) => theme.font.questrial};
  margin: 0 0 2rem 0;

  ${mq.xLarge(css`
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 2.5rem;
  `)};
`;

export const Fieldset = styled.fieldset`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 0;
  margin-right: 0;
`;

export const FieldsetDetails = styled(Fieldset)`
  padding-top: 0.875rem;
  margin-bottom: 2.5rem;
`;

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.core_white};
  font-size: 1.25rem;
  line-height: 2rem;
  border: none;
  border-radius: 4rem;
  text-align: center;
  box-shadow: none;
  position: relative;
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: background-color 300ms ease-in-out;

  ${boxShadow};
  ${buttonHover};

  ${mq.small(css`
    padding: 0.75rem 3rem;
    min-width: 17rem;
    width: auto;
  `)};

  ${mq.xLarge(css`
    padding: 0.75rem 1.5rem;
  `)};
`;

export const ContactFormResult = styled.div`
  background-color: ${({ theme }) => theme.colors.core_black};
  width: 100%;
  height: calc(100% + 2rem);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
`;

export const ResultButton = styled.button`
  border: 0.0625rem solid ${({ theme }) => theme.colors.core_white};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.core_white};
  padding: 0.75rem 4rem;
  font-size: 1.25rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4rem;
`;

export const ResultButtonLink = styled.a`
  border: 0.0625rem solid ${({ theme }) => theme.colors.core_white};
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.questrial};
  color: ${({ theme }) => theme.colors.core_white};
  padding: 0.75rem 4rem;
  font-size: 1.25rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4rem;
`;

export const ResultTitle = styled.p`
  ${ParagraphXXL};
  color: ${({ theme }) => theme.colors.core_white};
  margin: 0 0 1rem 0;
  text-align: center;

  ${mq.medium(css`
    font-size: 3rem;
    line-height: 4.5rem;
    margin-bottom: 0.5rem;
  `)};
`;

export const ResultDescription = styled.p`
  ${ParagraphS};
  color: ${({ theme }) => theme.colors.grey};
  font-weight: 400;
  margin: 0 0 2.5rem 0;
  text-align: center;

  ${mq.medium(css`
    font-size: 1.875rem;
    line-height: 3rem;
    margin-bottom: 3rem;
  `)};
`;

export const ResultContent = styled.div`
  ${defaultContainerSize};

  max-width: 35rem;
  text-align: center;
  align-self: flex-start;

  ${mq.small(css`
    align-self: center;
  `)};
`;

export const ResultIcon = styled.div`
  height: 3.25rem;
  width: 100%;
  overflow: visible;
  margin: 0 0 2.5rem 0;
  position: relative;

  ${mq.medium(css`
    margin-bottom: 3rem;
  `)};
`;
