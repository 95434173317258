import { css, Global } from '@emotion/react';
import { nunito, questrial } from '../common/consts/fonts';

const GlobalStyle = () => (
  <Global
    styles={css`
      :root {
        --font-base: ${questrial.style.fontFamily};
        --font-second: ${nunito.style.fontFamily};
      }

      html {
        scroll-behavior: auto !important;
      }

      body {
        font-family: var(--font-base);
        -webkit-tap-highlight-color: transparent;
      }

      a {
        text-decoration: none;
      }
    `}
  />
);

export default GlobalStyle;
