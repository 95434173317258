import { RichTextBlock } from 'prismic-reactjs';

export interface IFooterQueryData {
  company_contact_lines: Array<IContactLine>;
  company_address_lines: Array<IAddressLine>;
  direct_contact_title: Array<RichTextBlock>;
  legal_privacy_policy: Array<RichTextBlock>;
  nda_checkbox: Array<RichTextBlock>;
  legal_copyright: Array<RichTextBlock>;
  page_link: Array<IPageLink>;
  contact_form_title: Array<RichTextBlock>;
  contact_form_services: ISelect;
  contact_form_budget: ISelect;
  contact_form_name: IInput;
  contact_form_lastname: IInput;
  contact_form_email: IInput;
  contact_form_agreement: IInput;
  contact_form_project_details: IInput;
  contact_form_file_attachment: IInput;
  contact_form_submit: Array<RichTextBlock>;
  contact_form_agree_checkbox: Array<RichTextBlock>;
  contact_form_success_icon: RichTextBlock;
  contact_form_success_title: Array<RichTextBlock>;
  contact_form_success_description: Array<RichTextBlock>;
  contact_form_success_button: Array<RichTextBlock>;
  contact_form_error_icon: RichTextBlock;
  contact_form_error_title: Array<RichTextBlock>;
  contact_form_error_description: Array<RichTextBlock>;
  contact_form_error_button: Array<RichTextBlock>;
  microsoft_partner_image: RichTextBlock;
}

export interface IFooterProps {
  data?: IFooterQueryData;
}

export interface IContactLine {
  label: Array<RichTextBlock>;
  link: {
    url: string;
  };
  image: RichTextBlock;
}

export interface IAddressLine {
  label: Array<RichTextBlock>;
}

export interface IPageLink {
  name: Array<RichTextBlock>;
  page_url: string;
  is_sub_page: Array<RichTextBlock>;
}

export interface IPerson {
  name: Array<RichTextBlock>;
  position: Array<RichTextBlock>;
  link: {
    url: string;
  };
}

export interface IOption {
  value: Array<RichTextBlock>;
  label: Array<RichTextBlock>;
}

export interface ISelect {
  title: Array<RichTextBlock>;
  options: Array<IOption>;
  errors: Array<IFormError>;
}

export interface IInput {
  label: Array<RichTextBlock>;
  errors: Array<IFormError>;
}

export interface IFormError {
  name: string;
  message: Array<RichTextBlock>;
}

export interface IContactFormData {
  firstname: string;
  lastname: string;
  email: string;
  details: string;
  agreement: boolean;
  nda: boolean;
}

export enum Result {
  Success = 'success',
  Error = 'error',
  None = 'none',
}
